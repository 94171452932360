<template>
  <div class="body-inner default-mains">
    <BaseLoad v-if="userEventsLoad" />
    <ItemMeetingEvent :events="userEvents" v-if="userEvents.length" />
    <BaseLoad class="pos-s" v-if="isLoadMore" />
    <div v-if="!userEventsLoad && !userEvents.length" class="p-20 tac fs-18 fw-b h100 flex ai-c jc-c">
      Пока нет никаких событий!
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { ItemMeetingEvent } from '@/components'
import { useStore } from 'vuex'
import { BaseLoad } from '@/components'

// Data
const store = useStore()
const isLoadMore = ref(false)
const userEvents = computed(() => store.getters['helpers/userEvents'])
const page = computed(() => store.getters['helpers/getEventsPage'])
const pageCount = computed(() => store.getters['helpers/getEventsPageCount'])
const userEventsLoad = computed(() => store.getters['helpers/userEventsLoad'])
const currentTab = ref('events')

// Mounted
onMounted(() => {
  if (currentTab.value === 'events') {
    const el = document.querySelector('.default-mains')
    el.addEventListener('scroll', async function () {
      if (el.scrollTop > el.scrollHeight - 1000) await getNextRows()
    })
  }
})

// Methods
function getNextRows() {
  if (page.value < pageCount.value && !isLoadMore.value) {
    isLoadMore.value = true
    store.dispatch('helpers/GET_MORE_EVENTS_ROWS').finally(() => {
      isLoadMore.value = false
    })
  }
}
</script>

<style lang="scss" scoped>
.overflow-a-div {
  height: calc(100% - 50px);
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.body-inner {
  height: 100%;
  overflow-y: auto;
  width: 100%;
}
.base-sidebar {
  position: sticky;
  top: 0;
  width: 300px;
  height: calc(100vh - 70px);
  overflow: hidden;
  display: block;

  &__head {
    height: 50px;
    padding: 0 20px;
  }

  &__body {
    flex: 1;
    height: calc(100% - 50px);
    overflow: hidden;
    background-position: center;
    background-size: cover;
    // color: #fff;
    border-radius: 0 0 var(--br) var(--br);
  }
}
</style>
